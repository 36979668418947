import CheckBoxFeature from "../CheckBoxFeature/CheckBoxFeature";
import TestimonialChip from "../TestimonialChip/TestimonialChip";
import AvatarJorgeAguilera from "../../assets/JAP_AVATAR_1_500x500.png"
import AvatarFlorPalida from "../../assets/AVATAR FLOR PALIDA.jpg"
import AvatarArmandoParedes from "../../assets/AVATAR ARMANDO PAREDES.jpg"
import "./TestimonialsSection.css"
import Carousel from "../Carousel/Carousel";

export default function TestimonialsSection() {
    const testimonials = [
        // <TestimonialChip avatar={<img src={AvatarJorgeAguilera}/>} name={"Jorge Aguilera Perez"} testimonial={"No he tenido más que experiencias positivas del trabajo con esta empresa. Me hicieron una remodelación al baño principal de la vivienda y la verdad quedé asombrado con la entrega."}/>,
        <TestimonialChip avatar={<img src={AvatarFlorPalida}/>} name={"Flor Palida del Bosque"} testimonial={"Les presenté mi idea para hacer un quincho y me propusieron mejoras. Adoré el resultado final"}/>,
        <TestimonialChip avatar={<img src={AvatarArmandoParedes}/>} name={"Armando Paredes"} testimonial={"Les confié mi casa para que hicieran las remodelaciones necesarias para su venta. Sin duda fue la mejor decisión."}/>
    ]
    return(
        <div className="TestimonialsSection--MainContainer">
            <text style={{fontFamily:"Montserrat",fontSize:"24px",color:"#FFB800"}}>TESTIMONIOS</text>
            <text style={{fontFamily:"Montserrat",fontSize:"16px",color:"white",marginBottom:"20px"}}>VERIFIQUE TESTIMONIOS REALES DE NUESTROS CLIENTES</text>
            <Carousel children={testimonials}/>
        </div>
    );
}